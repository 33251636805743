import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    CleanHtml,
    FloatingLabelInput,
    PageContainer,
    PageWrapper,
    StandardButton,
    TextButton,
    useSelector,
} from "swiipe.portal.shared"
import DownloadTextButtonComp from "../../components/buttons/DownloadTextButtonComp/DownloadTextButtonComp"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import SubmitButton from "../../components/buttons/SubmitButton"
import FieldsetFormGroup from "../../components/form/FieldsetFormGroup"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import FileInput from "../../components/form/input/FileInput"
import PageTitle from "../../components/page/PageTitle"
import { paymentIQSelectors } from "../../store/reducers/paymentIQReducer"
import {
    adminEnableApplePaySwiipeCertsThunk,
    adminGetApplePaySwiipeInfoThunk,
    adminGetApplePaySwiipeSigningCertsThunk,
} from "../../store/thunks/piqThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { valFuncRequired } from "../../util/validationUtil"
import "./ApplePaySwiipeCertificatesSetup.scss"

const ApplePaySwiipeCertificatesSetup = () => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const applePayEnvOnboardingData = useSelector(paymentIQSelectors.getApplePayEnvOnboarding)
    const isLoaded = !!applePayEnvOnboardingData
    const isOnboarded = applePayEnvOnboardingData?.applePayId
    const [isRenewalFlow, setIsRenewalFlow] = useState(false)

    useEffect(() => {
        dispatch(adminGetApplePaySwiipeInfoThunk())
    }, [])

    return (
        <PageWrapper className="apple-pay-swiipe-certificates-setup-page">
            <PageContainer>
                <PageTitle title={t("applePaySetup.title")} />
                <SpinnerContainer showSpinner={!isLoaded}>
                    {isOnboarded && !isRenewalFlow ? (
                        <ShowApplePaySetupInfo
                            applePayId={applePayEnvOnboardingData.applePayId || ""}
                            setIsRenewalFlow={setIsRenewalFlow}
                        />
                    ) : (
                        <SetupApplePay isRenewalFlow={isRenewalFlow} setIsRenewalFlow={setIsRenewalFlow} />
                    )}
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

interface ISetupApplePayProps {
    isRenewalFlow: boolean
    setIsRenewalFlow(isRenewalFlow: boolean): void
}
const SetupApplePay = ({ isRenewalFlow, setIsRenewalFlow }: ISetupApplePayProps) => {
    type SetupApplePayForm = {
        applePayId: string
        uploadedCertificates: File[]
    }
    const { handleSubmit, register, setError, trigger, errors, clearErrors, getValues, formState, setValue } =
        useForm<SetupApplePayForm>()
    const { t } = useTranslation()

    const onSubmit: SubmitHandler<SetupApplePayForm> = async (data) => {
        await dispatch(adminEnableApplePaySwiipeCertsThunk(data.applePayId, data.uploadedCertificates[0]))
        if (isRenewalFlow) {
            setIsRenewalFlow(false)
        }
    }

    const dispatch = useReduxDispatch()
    const applePayEnvOnboardingData = useSelector(paymentIQSelectors.getApplePayEnvOnboarding)

    useEffect(() => {
        const existingApplePayId = applePayEnvOnboardingData?.applePayId
        if (!existingApplePayId) {
            return
        }
        setValue("applePayId", existingApplePayId)
    }, [applePayEnvOnboardingData])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="apple-pay-setup">
                <FieldsetFormGroup>
                    <div className="apple-pay-setup__step-description">{t("applePaySetup.appleMerchantId.desc")}</div>
                    <FloatingLabelInput
                        placeholder={t("applePaySetup.appleMerchantId.placeholder")}
                        name="applePayId"
                        innerRef={register(valFuncRequired(t("applePaySetup.appleMerchantId.error")))}
                    />
                    <div className="apple-pay-setup__generate-signing-request mt-2">
                        <StandardButton
                            isSmall
                            dark
                            noBorder
                            onClick={(e) => {
                                e.preventDefault()
                                const applePayId = getValues().applePayId
                                if (!applePayId) {
                                    return
                                }
                                dispatch(adminGetApplePaySwiipeSigningCertsThunk(applePayId))
                            }}
                        >
                            {t("applePaySetup.generateSigningRequestsBtn.text")}
                        </StandardButton>
                        {isRenewalFlow && <span>{t("applePaySetup.generateSigningRequestsBtn.renewalCaution")}</span>}
                    </div>
                </FieldsetFormGroup>

                <FieldsetFormGroup>
                    <CleanHtml
                        element="div"
                        className="apple-pay-setup__step-description"
                        html={t("applePaySetup.paymentProcessingCsr.desc")}
                    />
                    <DownloadTextButtonComp
                        className="apple-pay-setup__download-btn"
                        textToDownload={applePayEnvOnboardingData?.paymentProcessingCsr}
                        label={t("applePaySetup.paymentProcessingCsr.downloadText")}
                        fileName="paymentProcessingCsr.csr"
                        invertedBlue
                        isSmall
                    />
                </FieldsetFormGroup>

                <FieldsetFormGroup>
                    <CleanHtml
                        element="div"
                        className="apple-pay-setup__step-description"
                        html={t("applePaySetup.merchantIdentityCsr.desc")}
                    />
                    <DownloadTextButtonComp
                        className="apple-pay-setup__download-btn"
                        textToDownload={applePayEnvOnboardingData?.merchantIdentityCsr}
                        label={t("applePaySetup.merchantIdentityCsr.downloadText")}
                        fileName="merchantIdentityCsr.csr"
                        invertedBlue
                        isSmall
                    />
                </FieldsetFormGroup>

                <FieldsetFormGroup>
                    <CleanHtml
                        element="div"
                        className="apple-pay-setup__step-description"
                        html={t("applePaySetup.merchantIdentityCertificate.desc")}
                    />
                    <FileInput
                        containerClass="apple-pay-setup__file-upload"
                        name="uploadedCertificates"
                        accept=".cer"
                        minFilesAmount={1}
                        maxFilesAmount={1}
                        triggerValidation={trigger}
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        getValues={getValues}
                        register={register}
                        requiredErrorMessage={t("applePaySetup.merchantIdentityCertificate.error")}
                        multiple
                    />
                </FieldsetFormGroup>
                <ShowErrorMessages<SetupApplePayForm> errors={errors} />
                <SubmitButton dark noBorder formState={formState}>
                    {t("common.submit")}
                </SubmitButton>
                {isRenewalFlow && (
                    <TextButton className="mt-2" text={t("common.goBack")} onClick={async () => setIsRenewalFlow(false)} />
                )}
            </div>
        </Form>
    )
}

interface IShowApplePaySetupInfo {
    applePayId: string
    setIsRenewalFlow(isRenewalFlow: boolean): void
}
const ShowApplePaySetupInfo = ({ applePayId, setIsRenewalFlow }: IShowApplePaySetupInfo) => {
    const { t } = useTranslation()
    return (
        <div className="apple-pay-has-been-setup">
            <div className="apple-pay-has-been-setup__message">{t("applePaySetup.setUpSuccessfully")}</div>
            <FloatingLabelInput placeholder={t("applePaySetup.appleMerchantId.placeholder")} defaultValue={applePayId} disabled />
            <StandardButton
                isSmall
                dark
                noBorder
                className="apple-pay-has-been-setup__renew-button mt-2"
                onClick={() => setIsRenewalFlow(true)}
            >
                {t("applePaySetup.renewCertificates")}
            </StandardButton>
        </div>
    )
}

export default ApplePaySwiipeCertificatesSetup
