import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, CleanHtml, IModalBase, IModalControlProps, StandardButton } from "swiipe.portal.shared"
import "./ModalStandardInfo.scss"

export interface IModalStandardInfo extends IModalBase {
    type: "standardInfo"
    title: string
    text: string
    buttonText?: string
}

const ModalStandardInfo = ({ callback, title, text, buttonText, ...otherProps }: IModalStandardInfo & IModalControlProps) => {
    const { t } = useTranslation()

    return (
        <BaseModal
            {...otherProps}
            callback={callback}
            size="lg"
            modalClassName="modal-standard-info"
            centered
            noBorderTop
            title={title}
        >
            <ModalBody>
                <CleanHtml element="div" className="modal-standard-info__text" html={text} />

                <div className={cn("buttons mt-5 mb-3 centered")}>
                    <StandardButton
                        isMedium
                        onClick={() => {
                            callback("accepted")
                        }}
                    >
                        {buttonText ?? t("common.close")}
                    </StandardButton>
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalStandardInfo
