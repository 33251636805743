import cn from "classnames"
import React, { useState } from "react"
import InfoExclamation from "../../images/icons/info/info-exclamation.svg"
import InfoBoldBlue from "../../images/icons/info/info-icon-blue.png"
import InfoLightBlue from "../../images/icons/info/info-light-blue.png"
import InfoLightGray from "../../images/icons/info/info-light-gray.png"
import { StandardIcon } from "../icons/StandardIcon"
import { CleanHtml } from "./CleanHtml"
import "./TitleWithAbsoluteHint.scss"

interface ITitleWithAbsoluteHint {
    title?: string
    titleLight?: boolean
    hintText?: string
    hintTextWithHtml?: string
    children?: React.ReactNode
    iconLightGray?: boolean
    iconBold?: boolean
    iconExclamation?: boolean
    iconType?: "default"
    iconColor?: string
    noIcon?: boolean
    hintBoxWhite?: boolean
    hintAppearanceBottom?: boolean
    hintAppearanceTop?: boolean
    hintIconRight?: boolean
    className?: string
    titleClassName?: string
    largeHint?: boolean
}

export const TitleWithAbsoluteHint = ({
    title,
    titleLight,
    hintText,
    hintTextWithHtml,
    children,
    iconLightGray,
    iconBold,
    noIcon,
    iconExclamation,
    hintBoxWhite,
    hintAppearanceBottom,
    hintAppearanceTop,
    hintIconRight,
    className,
    titleClassName,
    iconType,
    iconColor,
    largeHint,
}: ITitleWithAbsoluteHint) => {
    const [showHint, setShowHint] = useState(false)

    return (
        <div
            className={cn("title-with-absolute-hint", className, {
                "hint-icon-right": hintIconRight,
                "title-light": titleLight,
                "no-hint": !hintText && !hintTextWithHtml && !children,
                "no-title": !title,
                "large-hint": largeHint,
            })}
            onMouseOver={() => setShowHint(true)}
            onMouseOut={() => setShowHint(false)}
        >
            <div className="hint-icon">
                {!noIcon && !iconType && (
                    <img
                        src={
                            iconLightGray
                                ? InfoLightGray
                                : iconBold
                                ? InfoBoldBlue
                                : iconExclamation
                                ? InfoExclamation
                                : InfoLightBlue
                        }
                    />
                )}
                {!noIcon && iconType && (
                    <StandardIcon
                        className="hint-icon-type"
                        componentForegroundColor={iconColor}
                        standardType="info"
                        width={16}
                    />
                )}
                {(hintText || hintTextWithHtml || children) && (
                    <>
                        <div
                            className={cn(
                                "hint-arrow",
                                { "d-none": !showHint },
                                { white: hintBoxWhite },
                                { "show-on-bottom": hintAppearanceBottom },
                                { "show-on-top": hintAppearanceTop }
                            )}
                        ></div>
                        <CleanHtml
                            element="div"
                            className={cn(
                                "hint",
                                { "d-none": !showHint },
                                { white: hintBoxWhite },
                                { "show-on-bottom": hintAppearanceBottom },
                                { "show-on-top": hintAppearanceTop }
                            )}
                            html={hintTextWithHtml ? hintTextWithHtml : undefined}
                        >
                            {hintText || children}
                        </CleanHtml>
                    </>
                )}
            </div>
            <div className={cn("hint-title", noIcon && "hint-title--no-icon", titleClassName)}>{title ?? ""}</div>
        </div>
    )
}
